import axios from "../core/axios";
import { Headers } from "./generic";
import { assertSuccess } from "../core/assert";
import type { Collection, CollectionResult, Result } from "../types/result";
import type {
  DynamicPermissionsEntity,
  Permission,
  PermissionEntity,
} from "../types/security";
import { paramsParser } from "../core";

// endpoints:
// /permission/<entity>/<id>            => get list of perms of entity
// /permission/<entity>/<id>/<user_id>  => get list of perms of user for entity, will fail if user cannot manage entity, i.e. does not have entity.manage

export class Security {
  static async getGroupMemberPermissions(
    groupId: string,
    auth: string,
  ): Promise<Collection<PermissionEntity>> {
    const res = await axios<CollectionResult<PermissionEntity>>(
      `/permission/organization/${groupId}?includes[]=user`,
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(res.data);
  }

  static async getSpecificGroupMemberPermissions(
    groupId: string,
    memberId: string,
    auth: string,
  ): Promise<Collection<PermissionEntity>> {
    const res = await axios<CollectionResult<PermissionEntity>>(
      `/permission/organization/${groupId}/${memberId}`,
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(res.data);
  }

  static async getPermissionsForOrg<Perms extends Permission>(
    groupId: string,
    auth: string,
    permissions?: Perms[],
  ): Promise<DynamicPermissionsEntity<Perms>> {
    const res = await axios<Result<DynamicPermissionsEntity<Perms>>>(
      `/permission/default/organization/${groupId}` +
        paramsParser({ permissions }),
      {
        headers: Headers.Bearer(auth),
      },
    );
    return assertSuccess(res.data).data;
  }

  static async setGroupMembersPermissions(
    groupId: string,
    memberId: string,
    permissions: {
      [key in Permission]: boolean;
    },
    auth: string,
  ): Promise<Collection<PermissionEntity>> {
    const res = await axios<CollectionResult<PermissionEntity>>(
      `/permission/organization/${groupId}/${memberId}`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: {
          permissions,
        },
      },
    );
    return assertSuccess(res.data);
  }

  static async removeGroupMemberPermissions(
    groupId: string,
    memberId: string,
    permissions: Permission[],
    auth: string,
  ): Promise<Collection<PermissionEntity>> {
    const res = await axios<CollectionResult<PermissionEntity>>(
      `/permission/organization/${groupId}/${memberId}`,
      {
        method: "DELETE",
        headers: Headers.Bearer(auth),
        data: {
          permissions,
        },
      },
    );
    return assertSuccess(res.data);
  }
}
